<template>
  <div class="d-flex-column align-center main mw-md">
    <el-card>
      <div class="text-body-wrapper">
        <h2 class="mt-0">You need an NRL Account to register</h2>
        <p>
          To register we will need you to create or have an NRL Account.
        </p>
        <p class="mb-0">
          If you don’t have an NRL Account, that’s okay, you can create a new Account when logging in.
        </p>
      </div>
    </el-card>

    <div class="d-flex-column loose button-transparent">
      <div class="align-text-center">
        <el-button id="continueRegoButton" type="primary" @click="login()"
          >Continue Registration</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LoginRequired",
  props: {
    loginRedirect: String
  },
  created() {
    if (this.isAuthenticated) {
      this.$router.push({ name: "verification" }); // oauth not needed
    } else if (this.loginRedirect) {
      this.$store.dispatch("views/updateLoginRedirect", this.loginRedirect);
    }
  },
  computed: {
    // get the type from store - in order to display the correct search result.
    ...mapGetters("user", ["isAuthenticated"])
  },
  methods: {
    login() {
      this.$router.push({ name: "login" }); // oauth
    }
  }
};
</script>

<style lang="scss">
.text-body-wrapper {
  h1,
  h2,
  h3,
  p {
    text-align: center;
  }
  h2 {
    font-family: $fontMedium, Arial;
  }
  p {
    font-size: 0.875em;
  }
}
</style>
